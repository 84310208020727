<template>
<div>
    <van-list v-if="list.length" :finished="finished" finished-text="没有更多了">
      <div class="list"  v-for="item of list" :key="item.id" @click="jumpIdea(item)">
        <div class="card" v-if="!item.sound_url && !item.video_url">
          <div class="title">{{ item.name }}</div>
          <div class="content">
            <van-image class="cover" fit="cover" 
            loading-icon="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E6%89%98%E8%82%B2%E9%AB%98%E7%A0%94%E9%99%A2%E9%A6%96%E9%A1%B5-%E6%8B%B7%E8%B4%9D_03.jpg" 
            error-icon="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E6%89%98%E8%82%B2%E9%AB%98%E7%A0%94%E9%99%A2%E9%A6%96%E9%A1%B5-%E6%8B%B7%E8%B4%9D_03.jpg" 
           
            :src="item.img_url" />
            <div class="detail">
              <p class="text">
                 {{item.content}}
              </p>
              <p class="more">阅读全文
                <i class="el-icon-arrow-right"></i>
              </p>
            </div>
          </div>
        </div>
        <div class="card2" v-if="!item.sound_url && item.video_url">
          <div class="title">{{ item.name }}</div>
          <div class="content">
            <van-image 
            loading-icon="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E6%89%98%E8%82%B2%E9%AB%98%E7%A0%94%E9%99%A2%E9%A6%96%E9%A1%B5-%E6%8B%B7%E8%B4%9D_03.jpg" 
            error-icon="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E6%89%98%E8%82%B2%E9%AB%98%E7%A0%94%E9%99%A2%E9%A6%96%E9%A1%B5-%E6%8B%B7%E8%B4%9D_03.jpg" 
            class="cover" fit="cover" :src="item.cover" />
            <div class="detail">
              <p class="text">
                {{item.content}}
              </p>
              <p class="more">阅读全文
                <i class="el-icon-arrow-right"></i>
              </p>
            </div>
          </div>
        </div>
        <div class="card3" v-if="item.sound_url">
          <div class="cardLeft">
            <div class="title">{{ item.name }}</div>
            <div class="time">
              <p>{{item.duration}}</p>{{item.pv_count}}人学习过
            </div>
          </div>
          <img src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/%E7%BC%96%E7%BB%84%202%E5%A4%87%E4%BB%BD%202%402x.png" @click.stop="toPlayAudio(item)"/>
        </div>
      </div>
    </van-list>
  <my-audio ref="audio" @closeAudio="closeAudio" :list="audioList" :isShowAudioPlayer="isShowAudioPlayer"></my-audio>
</div>
</template>

<script>
import myAudio from '@/components/Audio'
export default {
  name: "Home",
  components:{myAudio},
  props:['list'],
  data() {
    return {
      finished: false,
      isShowAudioPlayer:false,
      currentInfo:null,
      currentIndex:0,
    }
  },
  computed:{
    audioList(){
      return this.list.filter(item=>item.sound_url)
    }
  },
  mounted() {

  },

  methods: {
    closeAudio(){
      this.isShowAudioPlayer = false;
    },
    toPlayAudio(item){
      let index = this.audioList.findIndex(v=>v.id===item.id)
      this.$refs.audio.getData(index)
      this.isShowAudioPlayer = true
    },
    jumpIdea(item) {
      this.$router.push({
        path:'./ideaDetail',
        query:{
          id:item.id
        }
      })
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate')
    console.log(to)
    next()
  },

};
</script>

<style lang="scss" scoped>

.more {
          font-size: 14px;
          color: #a40001; 
          display: flex;
          align-items: center;
          margin-top: 10px;
          cursor: pointer;
        }
  .card {
    background: #fff;
    border-radius: 12px;
    padding: 20px 20px;
    margin-bottom: 20px;
    cursor: pointer;

    .title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .content {
      display: flex;
      width: 100%;

      .cover {
        flex: 0 0 280px;
        margin-right: 20px;
        width: 280px;
        height: 136px;
        border-radius: 10px;
        overflow: hidden;
        :deep .van-image__loading-icon{
          width: 100%;
          height: 100%;
          .van-icon__image{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .detail {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        flex: auto;

        .text {
          font-size: 14px;
          color: #666;
          overflow: hidden;
          display: -webkit-box;
          /*弹性伸缩盒子*/
          -webkit-box-orient: vertical;
          /*子元素垂直排列*/
          -webkit-line-clamp: 5;
          /*可以显示的行数，超出部分用...表示*/
          text-overflow: ellipsis;
          /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
        }

        

      }
    
    }
  }
  .card2{
    background: #fff;
    border-radius: 12px;
    padding: 20px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    .title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .content {
      width: 100%;

      .cover {
        width: 100%;
        height: 136px;
        border-radius: 10px;
        overflow: hidden;
      }

      .detail {

        .text {
          font-size: 14px;
          color: #666;
          overflow: hidden;
          display: -webkit-box;
          /*弹性伸缩盒子*/
          -webkit-box-orient: vertical;
          /*子元素垂直排列*/
          -webkit-line-clamp: 5;
          /*可以显示的行数，超出部分用...表示*/
          text-overflow: ellipsis;
          /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
        }

        

      }

    }
  }
  .card3{
    background: #fff;
    border-radius: 12px;
    padding: 20px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    .cardLeft{
      flex:1;
      .title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .time{
        color: #666;
        p{
          display: inline-block;
          padding-right: 20px;
          position: relative;
          &::after{
            content: '';
            display: block;
            position: absolute;
            width: 1px;
            height: 12px;
            background: #666;
            top:50%;
            transform: translateY(-50%);
            right: 10px;
          }
        }
      }
    }
    img{
      width: 40px;
      height: 40px;
    }
  }
  .type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    margin-bottom: 20px;

    .type-btn {
      width: 140px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a40001;
      background: #eee;
      border-radius: 4px;
      cursor: pointer;
      margin-right: 20px;
      transition: all 0.3s;

      &:hover {
        color: #fff;
        background: #a40001;
      }
    }

    .active {
      color: #fff;
      background: #a40001;
    }
  }

  .article {
    width: 960px;
    background: #fff;
    min-height: 100vh;
    margin: 20px auto;
    padding: 30px;

    .art-title {
      font-size: 32px;
      margin-bottom: 20px;
    }

    .art-user {
      display: flex;
      align-items: center;
    }

    .user-msg {
      font-size: 20px;
      color: #333;
      margin-left: 10px;
    }
  }

  .idea,
  .cert {

    .idea-nav,
    .idea-list,
    .cert-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .idea-nav-item {
      width: calc((100% - 200px) / 7);
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a40001;
      background: #eee;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        color: #fff;
        background: #a40001;
      }
    }

    .idea-card {
      margin-top: 40px;
      width: calc((100% - 60px) / 3);
      // height: 300px;
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      padding: 20px;

      .card-title {
        font-weight: 500;
        margin-bottom: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-subtitle {
        overflow: hidden;
        font-size: 18px;
        color: #666;
        overflow: hidden;
        display: -webkit-box;
        /*弹性伸缩盒子*/
        -webkit-box-orient: vertical;
        /*子元素垂直排列*/
        -webkit-line-clamp: 5;
        /*可以显示的行数，超出部分用...表示*/
        text-overflow: ellipsis;
        /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
      }
    }

    .cert-card {
      width: calc((100% - 60px) / 3);
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden;
      margin-bottom: 30px;
      margin-right: 20px;

      .card-detail {
        background: #fff;
        padding: 16px 16px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .card-cover {
        width: 100%;
        border-radius: 8px 8px 0 0;
        box-sizing: border-box;
      }

      .card-title {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 26px;
      }

      .card-btn {
        cursor: pointer;
        border-radius: 6px;
        line-height: 32px;
        font-weight: 500;
        border-radius: 6px;
        color: #a40001;
        background: #f7f7f7;
        padding: 0 20px;
        font-size: 16px;
        flex: 0 0 auto;

        &:hover {
          color: #fff;
          background: #a40001;
        }
      }
    }
  }
</style>

<style lang="scss">

</style>
